<template>
  <div>
    <h4 class="card-title">¿Qué deseas hacer?</h4>
    <div class="row align-items-baseline justify-content-between" style="gap: 15px;height: 60px">
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5 d-none"
              data-toggle="modal" data-target="#modal-add-payment" @click="showAddPayment">
        <i class='bx bx-user-plus btn-icon-prepend'></i>
        Crear nuevo pago
      </button>
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
              data-toggle="modal" data-target="#modal-upload-payments">
        <i class="ti-upload btn-icon-prepend"></i>
        Cargar facturas por archivo
      </button>
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5" @click="updateRange">
        <i class="ti-save btn-icon-prepend"></i>
        Realizar Pago masivo
      </button>
      <div class="dropdown">
        <button type="button" class="btn btn-outline-dark btn-sm dropdown-toggle" data-toggle="dropdown">
          <i class="bx bx-money-withdraw"></i> Lista de Facturas por estado
        </button>
        <div class="dropdown-menu">
          <h6 class="dropdown-header">Estados de factura</h6>
          <a class="dropdown-item" href="#" @click="filter='Iniciado'">Iniciado</a>
          <a class="dropdown-item" href="#" @click="filter='Pendiente'">Pendiente</a>
          <a class="dropdown-item" href="#" @click="filter='Cancelado'">Cancelado</a>
          <a class="dropdown-item" href="#" @click="filter='Declinado'">Declinado</a>
          <a class="dropdown-item" href="#" @click="filter='Pagado'">Pagado</a>
        </div>
      </div>
      <div class="form-group col-sm-6 col-md-3">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Buscar..."
                 @keyup="ev => filterSearch(ev, 'paymentIdPay', 'agreement.registerNumber')">
          <div class="input-group-append">
            <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="getPaymentWithFilter?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Listado de facturas en estado <span :class="`badge ${statesColor[filter]} align-bottom`" >{{filter}}</span> </p>
            <br/>
            <div class="table-responsive">
              <div class="row w-100">
              </div>
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th></th>
                  <th>Pago ID</th>
                  <th>Fecha Corte</th>
                  <th>Nª Matrícula</th>
                  <th>Estado</th>
                  <th>Valor</th>
                  <th>Comercio</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(historyPayment, index) in paginationData" :key="index">
                  <td ><input type="checkbox" @click="addCheckedItem(historyPayment,$event)"></td>
                  <td >{{ historyPayment?.paymentIdPay }}</td>
                  <td >{{ historyPayment?.paymentDate }}</td>
                  <td >{{ historyPayment?.agreement?.registerNumber }}</td>
                  <td >
                    <div class="badge badge-success" v-if="historyPayment?.statePayment === 'Pagado'">{{ historyPayment?.statePayment }}</div>
                    <div class="badge badge-warning" v-else-if="historyPayment?.statePayment === 'Pendiente'">{{ historyPayment?.statePayment }}</div>
                    <div class="badge badge-info" v-else-if="historyPayment?.statePayment === 'Iniciado'">{{ historyPayment?.statePayment }}</div>
                    <div class="badge badge-danger" v-else>{{ historyPayment?.statePayment }}</div>
                  </td>
                  <td >{{ utilService.formatCurrency(historyPayment?.payment?.value) }}</td>
                  <td >{{ historyPayment?.payment?.commerce }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="historyPaymentDetail = historyPayment" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver detalle
                        </button>
                        <button type="button" @click="showEdit(historyPayment)" class="p-3 dropdown-item"
                                data-toggle="modal">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Editar pago
                        </button>
                        <button type="button" class="p-3 dropdown-item"
                                @click="guidDelete = historyPayment.paymentGuid"
                                data-toggle="modal" data-target="#modal-remove-pay">
                          <i class='bx bx-trash align-middle'></i> &nbsp;&nbsp; Eliminar
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="getPaymentWithFilter.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <br/>
    <div class="card">
      <div class="card-body">
        <span class="card-title">No existe información de facturas <span :class="`badge ${statesColor[filter]}`" >{{filter}}</span></span>
      </div>
    </div>
  </div>
  <modal-component id-modal="modal-upload-payments" title="Carga de facturas por archivo">
    <template v-slot:modal-body>
      <form @submit.prevent="sendFilePayments">
        <div class="mb-3">
          <input class="form-control" type="file" name="file-payments" />
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="sendFilePayments">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-details" title="Detalle del pago">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title"># - {{ historyPaymentDetail?.paymentIdPay }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">Tipo de documento: {{ historyPaymentDetail?.customer.identificationType }}</p>
                  <p class="text-info mb-1">Identificación: {{ historyPaymentDetail?.customer.identification }}</p>
                  <p class="text-info mb-1">Referencia: {{ historyPaymentDetail?.payment.reference }}</p>
                  <p class="text-info mb-1">Referencia: {{ historyPaymentDetail?.payment.reference1 }}</p>
                  <p class="text-info mb-1">Referencia: {{ historyPaymentDetail?.payment.reference2 }}</p>
                  <p class="text-info mb-1">Valor del pago:
                    {{ utilService.formatCurrency(historyPaymentDetail?.payment.value) }}</p>
                  <p class="text-info mb-1">Estado: {{ historyPaymentDetail?.statePayment }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-add-payment" title="Editar pago" size="lg">
    <template v-slot:modal-body>
      <form @submit.prevent="addPayment" ref="form-add-payment">
        <div class="form-row">
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select class="form-control form-control-md"
                    name="identificationType"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model.trim="model.identificationType">
              <option selected>Seleccione Tipo de identificación...</option>
              <option value="CC">Cédula de ciudadanía</option>
              <option value="CE">Cédula de extranjería</option>
              <option value="TI">Tarjeta de identidad</option>
              <option value="PA">Pasaporte</option>
              <option value="RC">Registro Civil</option>
              <option value="OT">Desconocido</option>
              <option value="NUIP">Numero Unico de Identificación Personal</option>
              <option value="NI">NIT</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Identificación</label>
            <input type="text" class="form-control"
                   name="identification"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.identification">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Comercio</label>
            <input type="text" class="form-control"
                   name="commerce"
                   data-required="false"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.commerce">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Descripción del pago</label>
            <input type="text" class="form-control"
                   name="paymentDescription"
                   v-model.trim="model.paymentDescription">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Referencia del pago #1</label>
            <input type="text" class="form-control"
                   name="paymentReference"
                   data-required="false"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.paymentReference">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Referencia del pago #2</label>
            <input type="text" class="form-control"
                   name="paymentReference1"
                   v-model.trim="model.paymentReference1">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Referencia del pago #3</label>
            <input type="text" class="form-control"
                   name="paymentReference2"
                   v-model.trim="model.paymentReference2">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Valor a pagar</label>
            <input type="text" class="form-control"
                   name="paymentValue"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-regex="^(?:\d*[0-9](?:\.[0-9]{1,2})?|\.[0-9]{1,2})$"
                   :data-regex-msg="validatorMessages.NUMBER"
                   v-model.trim="model.paymentValue">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Fecha máxima</label>
            <input type="datetime-local" class="form-control"
                   name="paymentDateMax"
                   v-model.trim="model.paymentDateMax">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">ID del pago</label>
            <input type="text" class="form-control"
                   name="paymentId"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.paymentId">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número de matrícula</label>
            <input type="text" class="form-control"
                   name="registerNumber"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.registerNumber">
          </div>
          <div class="col-sm-12 col-md-4 mb-3" v-if="isEdit">
            <label class="form-label">Estado</label>
            <select class="form-control form-control-md"
                    name="stateGuid"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model.trim="model.stateGuid">
              <option :value="'77BE0184-C174-460F-850A-329A88BD9503'.toLowerCase()" >Pendiente</option>
              <option :value="'117DAFB5-C802-4CB9-8BC0-9B775986AA86'.toLowerCase()" >Pagado</option>
              <option :value="'56DCE22F-FBB5-451A-892F-1BCF2D30B1D5'.toLowerCase()" >Iniciado</option>
              <option :value="'71C4B3F9-241D-421A-BA01-9B0DE1BA3A99'.toLowerCase()" >Declinado</option>
              <option :value="'5F05AAA7-19CD-458A-B3C6-9704447FE469'.toLowerCase()" >Cancelado</option>
            </select>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addPayment">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-remove-pay" title="Eliminar factura">
    <template v-slot:modal-body>
      <h4 class="text-center">¿En verdad desea eliminar este registro?</h4>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" @click="remove">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/ModalComponent";
import httpService from "@/services/httpService";
import modalService from "@/services/modalService";
import toastService from "@/services/toastService";
import dateTimeService from "@/services/dateTimeService";
import validatorService, { validatorMessages } from "@/services/validatorService";
import utilService from "@/services/utilService";
import {mapGetters} from "vuex";
import axios from "axios";

const fieldsToValidate = [
  "identificationType",
  "identification",
  "commerce",
  "paymentDescription",
  "paymentReference",
  "paymentValue",
  "paymentId",
  "registerNumber",
]

export default {
  name       : "PaymentView",
  components : {ModalComponent},
  data:()=>({
    originalHistoryPayment :[],
    historyPaymentAll      :[],
    paymentAll             :[],
    historyPaymentDetail   :null,
    model                  :{},
    isEdit                 : false,
    filter                 : "Pendiente",
    guidDelete             : null,
    currentPage            : 1,
    pageSize               : 10,
    statesColor          :{
      "Iniciado"  : "badge-info",
      "Pendiente" : "badge-warning",
      "Cancelado" : "badge-danger",
      "Declinado" : "badge-danger",
      "Pagado"    : "badge-success",
    },
    dateTimeService,
    validatorMessages,
    validatorService,
    utilService,
    checkedPayments : []
  }),
  computed:{
    ...mapGetters(['getCitiesAndDepartments', 'getUserInfo']),
    getPaymentWithFilter(){
    let search = this.historyPaymentAll.filter(x => x?.statePayment === this.filter)
    console.log({filter:this.filter,historyPaymentAll:this.historyPaymentAll,search:search})
      return search
    },
    paginationData(){
      return utilService.filterData(this.getPaymentWithFilter, this.currentPage, this.pageSize)
    }
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  mounted() {
    this.getAll();
    this.resetForm();
  },
  methods:{
    resetForm(){
      this.$refs["form-add-payment"].reset()
    },
    getAll(){
      const paymentAll        = httpService.get("/HistoryPayment/GetAll", false)
      const historyPaymentAll = httpService.get("/Payment/GetAll", false)
      axios.all([paymentAll, historyPaymentAll])
          .then(res => {
            this.originalHistoryPayment = res[0]?.data?.data
            this.historyPaymentAll      = res[0]?.data?.data
            this.paymentAll             = res[1]?.data?.data
          })
    },
    getHistoryPaymentAll(){
      httpService.get("/HistoryPayment/GetAll", false)
        .then(({data}) => this.historyPaymentAll = data.data)
        .catch(() => toastService.error())
    },
    getPaymentAll(){
      httpService.get("/Payment/GetAll", false)
        .then(({data}) => this.paymentAll = data.data)
        .catch(() => toastService.error())
    },
    currencyFormat(value) {
      return value?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },
    sendFilePayments(){
      const formData = new FormData(document.querySelector("form"))
      formData.append("file", document.querySelector("input[name=file-payments]").files[0])
      const ACTION = "Payment/LoadFile"
      httpService.post(ACTION, formData, {"Content-Type": "multipart/form-data"})
          .then(() => {
            this.getAll();
            this.getPaymentWithFilter;
          })
          .finally(()=> modalService.hideModal("modal-upload-payments"))
    },
    showAddPayment(){
      this.resetForm();
      this.model    = {};
      this.resetForm();
      this.isEdit   = false;
      validatorService.validateAll(fieldsToValidate);
    },
    addPayment(){
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel            = {...this.model}
      dataModel.userName         = this.getUserInfo.userName
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action   : flagUpdate ? "/Payment/Update" : "/Payment/Create",
        data     : dataModel
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-payment");
            this.getAll();
            this.model = {}
          })
    },
    remove(){
      httpService.delete("Payment/Delete?guid=" + this.guidDelete)
          .then(() => {
            modalService.hideModal("modal-remove-pay");
            this.getAll();
            this.guidDelete = null;
          })
    },
    showEdit(payment){
      console.log({funcion:'showEdit',payment:payment})
      this.resetForm();
      this.isEdit = true;
      this.resetForm()
      this.model = {
        guid                : payment?.guid,
        identificationType  : payment?.customer?.identificationType,
        identification      : payment?.customer?.identification,
        commerce            : payment?.payment?.commerce,
        paymentDescription  : payment?.payment?.description,
        paymentReference    : payment?.payment?.reference,
        paymentReference1   : payment?.payment?.reference1,
        paymentReference2   : payment?.payment?.reference2,
        paymentValue        : `${payment?.payment?.value}`,
        paymentDateMax      : payment?.payment?.dateMax?.replace("T", " ") ?? payment?.payment?.dateMax?.split(" ")[0],
        paymentId           : payment?.payment?.id,
        registerNumber      : payment?.agreement.registerNumber,
        stateGuid           : this.historyPaymentAll.find(x => x.guid === payment?.guid).statePaymentGuid,
      }
      modalService.showModal('modal-add-payment');
      validatorService.reset(this.model)
    },
    filterSearch(ev, ...args) {
      this.historyPaymentAll = [...this.originalHistoryPayment]
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.getPaymentWithFilter.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item])
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(dataResult)
          })
        })
        this.historyPaymentAll = [...data]
      }
    },
    addCheckedItem(item,event){
      console.log({funcion:'addCheckedItem',item:item,event:event})
      if(event.target.checked){
        let modelPayment = this.createrModelStatePay(item)
        this.checkedPayments.push(modelPayment)
      }else{
        let modelPayment = this.createrModelStatePay(item)
        for (let index = 0; index < this.checkedPayments.length; index++) {
          const element = this.checkedPayments[index];
          if(element.guid == modelPayment.guid){
            this.checkedPayments.splice(index,1);
            break;
          }
        }

      }
      console.log({checkedPayments:this.checkedPayments})
    },
    createrModelStatePay(item){
      console.log({item:item})
      return {
          guid                : item?.payment.guid,
          identificationType  : item?.customer?.identificationType,
          identification      : item?.customer?.identification,
          commerce            : item?.payment?.commerce || "LOCAL",
          paymentDescription  : item?.payment?.description,
          paymentReference    : item?.payment?.reference || item?.payment?.id,
          paymentReference1   : item?.payment?.reference1,
          paymentReference2   : item?.payment?.reference2,
          paymentValue        : `${item?.payment?.value}`,
          paymentDateMax      : item?.payment?.dateMax?.replace("T", " ") ?? item?.payment?.dateMax?.split(" ")[0],
          paymentId           : item?.payment?.id,
          registerNumber      : item?.agreement.registerNumber,
          stateGuid           : "117DAFB5-C802-4CB9-8BC0-9B775986AA86",
          userName            : this.getUserInfo.userName
        }
    },
    updateRange(){
      if (this.checkedPayments.length == 0){

        return;
      }

      const options = {
        method   :  httpService.types.PUT ,
        action   : "/Payment/UpdateRange",
        data     : this.checkedPayments
      }
      httpService.execute(options)
          .then(() => {
            this.getAll();
            this.checkedPayments = {}
          })
    }
  }
}
</script>
